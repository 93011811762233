<template>
	<div class="animated fadeIn">
		<div
			class="row">
			<div class="col-md-4">
				<div
					v-if="($user.details().type === distributor || $user.details().type === preDistributor) && !loading"
					class="row mb-5">
					<div class="col-12 mx-auto text-center">
						<div
							v-if="profilePictureEditMode"
							class="mb-3">
							<vue-avatar
								ref="vueavatar"
								:width="profileImage.WIDTH"
								:height="profileImage.HEIGHT"
								:border-radius="profileImage.ROUNDED"
								:scale="Number(scale)"
								@select-file="onImageReady" />
							<div class="form-group mt-2">
								<label
									class="font-weight-bold"
									for="zoomControl">{{ translate('zoom') }}</label>
								<input
									id="zoomControl"
									v-model="scale"
									type="range"
									class="form-control-range custom-range"
									min="1"
									max="3"
									step="0.02">
							</div>
							<div class="row">
								<div class="col">
									<b-button
										:disabled="!imageReady"
										class="mx-1 mt-1"
										variant="primary"
										@click="saveClicked">
										{{ translate('save') }}
									</b-button>
									<b-button
										class="mx-1 mt-1"
										variant="secondary"
										@click="profilePictureEditMode = false; imageReady = false;">
										{{ translate('cancel') }}
									</b-button>
								</div>
							</div>
						</div>
						<a
							v-show="!profilePictureEditMode"
							class="image mb-3 col-12"
							@click="profilePictureEditMode = true"
							@mouseover="profilePictureHovered = true"
							@mouseleave="profilePictureHovered = false">
							<div class="square mx-auto">
								<div class="image-container">
									<img
										id="avatar"
										class="img-avatar border border-secondary w-100">
									<span class="case" />
								</div>
							</div>
							<div
								v-if="profilePictureHovered"
								class="text pointer">
								<div class="text-white">
									<i
										class="fa fa-camera fa-3x"
										aria-hidden="true" />
									<h3>{{ translate('update') }}</h3>
								</div>
							</div>
						</a>
						<div
							v-if="profilePhoto"
							class="btn btn-danger mx-1 pointer"
							@click="deleteClicked">
							{{ translate('delete_image') }}
						</div>
					</div>
				</div>
				<ul
					v-if="[distributor, preDistributor, agency].includes($user.details().type) || customerRoles.includes($user.details().type)"
					class="list-group mb-4">
					<router-link
						v-if="hasAccessByRole('PersonalInformation')"
						:class="$route.name === 'PersonalInformation' ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'PersonalInformation'}"
						class="list-group-item btn text-left">
						<i class="fas fa-user-alt mr-2" />{{ translate('personal_info') }}
					</router-link>
					<router-link
						v-if="hasAccessByRole('AccountInformation') && !this.$user.details().no_sponsor"
						:class="$route.name === 'AccountInformation' ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'AccountInformation'}"
						class="list-group-item btn text-left">
						<i class="fas fa-clipboard-list mr-2" />{{ translate('account_info') }}
					</router-link>
					<router-link
						v-if="coApplicantsData > 0 && hasAccessByRole('CoApplicants')"
						:class="$route.name === 'CoApplicants' ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'CoApplicants'}"
						class="list-group-item btn text-left">
						<i class="fas fa-user-plus mr-2" />{{ translate('co_applicants') }}
					</router-link>
					<router-link
						v-if="hasAccessByRole('ReplicatedSite')"
						:class="$route.name === 'ReplicatedSite' ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'ReplicatedSite'}"
						class="list-group-item btn text-left">
						<i class="fas fa-link mr-2" />{{ translate('replicated_site') }}
					</router-link>
					<router-link
						v-if="hasAccessByRole('AgencyReplicatedSite')"
						:class="$route.name === 'AgencyReplicatedSite' ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'AgencyReplicatedSite'}"
						class="list-group-item btn text-left">
						<i class="fas fa-link mr-2" />{{ translate('replicated_site') }}
					</router-link>
					<router-link
						v-if="hasAccessByRole('AddressBook')"
						:class="['AddressBook', 'NewProfileAddress', 'UpdateProfileAddress'].includes($route.name) ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'AddressBook'}"
						class="list-group-item btn text-left">
						<i class="fas fa-map-marker-alt mr-2" />{{ translate('address_book') }}
					</router-link>
					<router-link
						v-if="hasAccessByRole('CreditCard')"
						:class="['CreditCard', 'NewProfileCreditCard', 'UpdateProfileCreditCard', 'AllCreditCards', 'BackupCreditCards'].includes($route.name) ? $router.options.linkActiveClass : 'text-primary'"
						:to="{name: 'AllCreditCards'}"
						class="list-group-item btn text-left">
						<i class="fas fa-user-plus mr-2" />{{ translate('credit_card') }}
					</router-link>
				</ul>
			</div>
			<div :class="[distributor, preDistributor, agency].includes($user.details().type) || customerRoles.includes($user.details().type) ? 'col-md-8': 'col-12'">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>

import { VueAvatar } from 'vue-avatar-editor-improved';
import {
	distributor, customerRoles, preDistributor, agency,
} from '@/settings/Roles';
import { WHITE_LIST_MIME_IMAGES as whiteList } from '@/settings/Images';
import { PROFILE_DIMENSIONS as profileImage, DEFAULT_PROFILE_PICTURE_SIZE, PROFILE_PICTURE_FIELD_NAME } from '@/settings/Profile';
import {
	Profile as ProfileTrans, Validations, Users, Alerts,
} from '@/translations';
import CoApplicants from '@/util/CoApplicants';
import Profile from '@/util/Profile';
import Permission from '@/util/Permission';
import EventBus from '@/util/eventBus';

const defaultImage = require('@/assets/images/common/defaultAvatar.png');

export default {
	name: 'Profile',
	messages: [ProfileTrans, Validations, Users, Alerts],
	components: { VueAvatar },
	data() {
		return {
			alert: new this.$Alert(),
			defaultImage,
			agency,
			distributor,
			customerRoles,
			preDistributor,
			profileImage,
			profile: new Profile(),
			profileCoApplicants: new CoApplicants(),
			permission: new Permission(),
			profilePictureEditMode: false,
			profilePictureHovered: false,
			scale: 1,
			profilePhoto: '',
			imageReady: false,
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.profile.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		coApplicantsData() {
			try {
				const { total } = this.profileCoApplicants.data.response.data.meta;
				return total;
			} catch (error) {
				return 0;
			}
		},
		loading() {
			try {
				return !!this.profile.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	watch: {
		loading(value) {
			if (!value) {
				this.profilePictureEditMode = false;
			}
		},
	},
	mounted() {
		if (this.$user.details().type === this.distributor || this.$user.details().type === this.preDistributor) {
			this.getData();
			this.profileCoApplicants.getCoApplicants(this.$user.details().id);
		}
	},
	methods: {
		getData() {
			this.profile.getProfilePhoto(this.$user.details().id, DEFAULT_PROFILE_PICTURE_SIZE).then((response) => {
				this.profilePhoto = response.attributes.profile_photo;
				if (response.attributes.profile_photo) {
					document.getElementById('avatar').src = `${response.attributes.profile_photo}?v=${new Date().getTime()}`;
				} else if (!response.attributes.profile_photo) {
					document.getElementById('avatar').src = this.defaultImage;
				}
			});
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors.clear(field);
			}
		},
		dataURItoBlob(dataURI) {
			let byteString;
			if (dataURI.split(',')[0].indexOf('base64') >= 0) {
				byteString = atob(dataURI.split(',')[1]);
			} else {
				byteString = unescape(dataURI.split(',')[1]);
			}
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i += 1) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new Blob([ia], { type: mimeString });
		},
		onImageReady(event) {
			this.imageReady = whiteList.includes(event[0].type);
		},
		saveClicked() {
			if (this.imageReady) {
				const img = this.$refs.vueavatar.getImageScaled();
				const formData = new FormData();
				const blob = this.dataURItoBlob(img.toDataURL());
				formData.append('profile_photo', blob);
				this.profile.updateProfilePicture(this.$user.details().id, formData).then(() => {
					this.profile.getProfilePhoto(this.$user.details().id, DEFAULT_PROFILE_PICTURE_SIZE).then((response) => {
						const newPhoto = {};
						newPhoto[PROFILE_PICTURE_FIELD_NAME] = `${response.attributes[PROFILE_PICTURE_FIELD_NAME]}?rand=${parseInt(((1 / Math.random()) * 1000000000), 10)}`;
						this.$user.setUser(newPhoto);
						this.$forceUpdate();
					});
					this.imageReady = false;
				}).finally(() => {
					try {
						this.alert.toast('success', this.translate('image_updated'));
					} catch (error) {
						this.alert.toast('success');
					}
					this.getData();
					EventBus.$emit('updateAccountSteps');
				});
				this.profileEditMode = false;
			}
		},
		deleteClicked() {
			this.alert.confirmation(this.translate('delete_profile_picture_title'), this.translate('delete_profile_picture_text'), { confirmButtonText: this.translate('ok'), cancelButtonText: this.translate('cancel') }, false).then(() => {
				this.profile.deleteProfilePicture(this.$user.details().id).then(() => {
					const newPhoto = {};
					newPhoto[PROFILE_PICTURE_FIELD_NAME] = '';
					this.$user.setUser(newPhoto);
				}).finally(() => {
					try {
						this.alert.toast('success', this.translate('image_reset'));
					} catch (error) {
						this.alert.toast('success');
					}
					this.getData();
					EventBus.$emit('updateAccountSteps');
				});
				this.profileEditMode = false;
			}).catch(() => {});
		},
	},
};
</script>

<style>
	.image {
		position: relative;
		display: inline-block;
	}
	.image .case {
		position: absolute;
		left: 0;
		top: 0;
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 50em;
		transition: all 0.3s ease-out;
	}

	.image:hover .case {
		background-color: rgba(0, 0, 0, 0.7);
	}

	.image .text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	#avatarEditorCanvas {
		width: 70%;
		height: auto;
	}
</style>
